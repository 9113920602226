#HavenHeader {
  width: 100vw;
  background-color: black;
  height: 14vh;
}

#KeypadGrid {
  display: inline-grid;
  float: left;
  font-size: 16vh;
  line-height: 23.75vh;
  width: 48vw;
  height: 100vh;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 25vh 25vh 25vh 25vh;
}

#YesNoGrid {
  display: inline-grid;
  float: left;
  font-size: 8vh;
  line-height: 13.75vh;
  width: 46vw;
  height: 100vh;
  column-gap: 10vw;
  margin-left: 1.25vw;
  justify-content: center;
  grid-template-columns: 60%;
  row-gap: 3vh;
  grid-template-rows: 15vh 15vh 15vh 15vh;
}

#KeypadLeft {
  width: 49vw;
  text-align: center;
  font-size: 3.5vw;
  height: 100vh;
  float: left;
}

.KeyPadBlock {
  background-color: #dcdcdc;
  border-radius: 0.4rem;
  text-align: center;
  margin: 0.5rem;
  margin-top: 0vh;
  margin-bottom: 1.25vh;
}

#PTOCalendar .p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  font-size: 5vw;
}
#PTOCalendar .dateDayMimic {
  width: 9rem;
  height: 6rem;
  line-height: 5rem;
  font-size: 2.5vw;
  text-align: center;
}

#PTOCalendar .p-datepicker table td > span {
  width: 4.5vw;
  height: 5.5vh;
}

#PTOCalendar .p-datepicker table th > span {
  width: 4.5vw;
  height: 5.5vh;
  font-size: 1.5vw;
}

#PTOCalendar .p-datepicker table th {
  text-align: center;
}
.terminalLargeDropdown {
  width: 30vw;
}
.terminalLargeDropdown span,
.terminalLargeDropdown .p-dropdown-items-wrapper ul {
  font-size: 2vw;
}

.dateDayMimic.approved {
  background-color: rgb(150, 201, 62);
  color: white;
}

.dateDayMimic.rejected {
  background-color: rgb(231, 56, 39);
  color: white;
}

.dateDayMimic.pending {
  background-color: rgb(39, 170, 226);
  color: white;
}
