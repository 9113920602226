.wifi-symbol {
  /* display: none; */
  display: block;
}

@keyframes example {
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}

/* .wifi-symbol [foo], */
.wifi-symbol {
  display: block;
  /* position: absolute; */
  /* top: 50%; */
  /* display: inline-block; */
  width: 100%;
  height: 100%;
  /* margin-top: -50px; */
  /* margin-top: -187.5px; */
  /* -ms-transform: rotate(-45deg) translate(-100px); */
  /* -moz-transform: rotate(-45deg) translate(-100px); */
  /* -o-transform: rotate(-45deg) translate(-100px); */
  /* -webkit-transform: rotate(-45deg) translate(-100px); */
  transform: rotate(-45deg);
}

.wifi-symbol.animate .wifi-circle {
  animation: example 3s infinite;
}

.wifi-symbol .wifi-circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  /* border-color: #ffc; */
  border-style: solid;
  border-width: 1em 1em 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
  /* opacity: 0; */
}
.wifi-symbol .wifi-circle.first {
  /* border-color: blue; */
  -o-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.wifi-symbol .wifi-circle.second {
  width: 75%;
  height: 75%;
  /* border-color: aqua; */
  -o-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.wifi-symbol .wifi-circle.third {
  width: 50%;
  height: 50%;
  /* border-color: purple; */
}
.wifi-symbol .wifi-circle.fourth {
  width: 25%;
  height: 25%;
  opacity: 1;
  -o-animation: none;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
}
