:root {
  --haven-red: rgb(238, 32, 35);
  --haven-darkred: rgb(214, 24, 31);
  --default-blue: #2196f3;
  --default-darkblue: #0d89ec;
  --rx-green: #8bbd34;
  --rx-darkgreen: #235921;

  --main-color: var(--haven-red);
  --main-color-shaded: var(--haven-darkred);
  --secondary-color: var(--default-blue);
  --secondary-color-shaded: var(--default-darkblue);
  --panel-color: rgb(0, 0, 0);
}

.ColumnFooter {
  border-top: 4px solid #e9ecef;
  background-color: #e6e6e6;
}

.ColumnHeader {
  background-color: #e6e6e6;
  border-bottom: 4px solid #e9ecef;
}

.ColumnHeaderFooter {
  text-align: center;
  font-size: 1.5vw;
  background-color: #f8f9fa;
}

.primereactfix-large-font-size-dropdown span {
  font-size: 0.8em;
}
.primereactfix-squished-table {
  padding: 0.3rem !important;
}
.dateDayMimic {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2rem;
  border-radius: 50%;
  border: 4px solid transparent;
  text-align: center;
}
.outlineMimic {
  border: 4px solid #0d89ec;
}

.createUserInput {
  width: 70%;
  padding: 0.12em;
  font-size: 1em;
}

.editCardInput {
  opacity: 1 !important;
}

.employeeCardInput {
  width: 80%;
  padding: 0.12em;
  font-size: 1em;
  opacity: 1 !important;
}

.menuButton {
  border-radius: 0.45em;
  color: white;
  font-size: 1.5vw;
  line-height: 3.5vh;
  padding: 0.13em;
  text-align: center;
  background-color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

.createShiftButton {
  width: 100%;
  height: 5vh;
  font-size: 1.5em;
}

.PrimeReactFixTableScrollable {
  flex-basis: 5em !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  overflow: hidden;
}
#table .ui-datatable-scrollable-body table {
  width: auto;
}
.adminPageButtons {
  height: 5vh;
  width: 8vw;
  font-size: 1.25em;
  float: right;
}

.adminPageButtons.red,
.createShiftButton {
  background: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

.adminPageButtons.white {
  background: white !important;
  border: 1px solid black !important;
  color: black !important;
}

.adminPageButtons.green {
  background: var(--rx-green) !important;
  border: 1px solid black !important;
  color: white !important;
}

.p-disabled.editCardInput {
  background: #f8f9fa !important;
}

.editCardInput .p-disabled {
  background: #f8f9fa !important;
}

.expire-row {
  background-color: #f2eeee !important;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #bdb2b2;
}

.marked-row {
  background-color: #ffc107;
  font-weight: bold;
}
