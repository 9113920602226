.primereactfix-center-column-header .p-column-header-content {
  display: block !important;
  text-align: center;
}

.hideThisRow {
  display: none !important;
  background-color: rgb(168, 224, 238) !important;
}

.hideThisHeader {
  background-color: rgb(168, 224, 238) !important;
}

.p-rowgroup-header-name {
  width: 100%;
}
.p-rowgroup-header:has(td):has(.p-rowgroup-header-name):has(.hideThisHeader) {
  display: none !important;
  opacity: 0.3;
}

.search-text {
  padding-left: 10%;
  font-size: 2.25vh !important;
  margin-bottom: 1%;
  margin-top: 1%;
  padding-bottom: 0;
}

#ReportSettings .btn-grid {
  margin-top: 2%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.search-block {
  width: 80%;
  left: 10%;
  height: 6vh;
  bottom: 10%;
  margin-bottom: 0.5%;
}

#Department .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#Shift .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#Active .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#PayType .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#WorkerType .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#DateRange .p-inputtext {
  font-size: 2.5vh !important;
}

#AttendanceCode .p-multiselect-label.p-placeholder {
  font-size: 2.5vh !important;
}
#AttendanceCode .p-multiselect-label.p-multiselect-items-label {
  font-size: 2.5vh !important;
}

#ApprovedShifts .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}

#User .p-dropdown-label.p-inputtext {
  font-size: 2.5vh !important;
}
/* 
#Department .p-dropdown-label.p-inputtext {
  font-size: 1em !important;
} */
@media print {
  .hideMe {
    display: none !important;
  }
  .showMe {
    display: inline-block !important;
  }
  .showMeGrid {
    display: grid !important;
  }
  .GrayPadding {
    background-color: transparent !important;
  }
  .site-layout-background {
    overflow: visible !important;
  }
}
.centerDropdownText .p-dropdown-label.p-inputtext {
  position: relative;
  top: 0vh;
}

.site-layout-background {
  height: 100%;
  overflow: auto;
}
.showMe {
  display: none;
}
.showMeGrid {
  display: none;
}

.GrayPadding {
  margin: 0.75em;
  background-color: #f8f9fa;
}

#PrimeReactDataTableOddRow .p-row-odd {
  background-color: #f8f9fa;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 2px;
    padding-bottom: 24px;
  }
  section {
    font-size: 1px;
    letter-spacing: 1px;
    background-color: aliceblue;
  }
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  
}
