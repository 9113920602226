#ModalIMG {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#ModalTitle {
  position: absolute;
  top: 0px;
  color: white;
  margin-left: 0.25em;
}

#ModalButton {
  position: absolute;
  top: 2.5%;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  right: 2.5%;
  font-size:37px;
  color:rgb(241, 226, 226);;
}

#ModalButton:hover{
  background-color: rgba(252, 253, 252, 0.61);
  font-size:40px;
}



