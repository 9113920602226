/** @format */

.navbar Nav.Link {
  font-size: 35px;
  color: white;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

@media print {
  .Nav {
    display: none !important;
  }
  .navbar Nav.Link {
    display: "none";
  }
}

.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
}

.ant-switch {
  margin-right: 1.5vw;
  background-color: #ef0909;
  transform: scale(1.5);
}
.ant-switch-checked {
  background-color: #2ea62e;
}
.ant-switch-handle {
  padding: none;
}
.ant-switch-inner {
  font-weight: bold;
}
.scrollpanel-demo .p-scrollpanel p {
  padding: 0.5rem;
  line-height: 1.5;
  margin: 0;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color 0.2s;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: #007ad9;
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-b);
  border-bottom: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-bar {
  background-color: var(--surface-d);
  border-radius: 0;
  opacity: 1;
  transition: background-color 0.2s;
}

.scrollpanel-demo .col-12 {
  padding: 2rem;
}

.accept {
  color: #fff;
  background-color: #44cc44;
  margin-left: 15vw;
  box-shadow: 0 4px 0 0 #2ea62e;
  border: none;
  border-radius: 0.45em;
}
.accept:hover {
  background-color: #6fe76f;
  box-shadow: 0 4px 0 0 #7ed37e;
}
.accept:disabled {
  background-color: gray;
  box-shadow: 0 0 0 0;
}
.info {
  color: #fff;
  background: #cc4444;
  margin-left: 15vw;
  box-shadow: 0 4px 0 0 #a62e66;
  border: none;
  border-radius: 0.45em;
}
.info:hover {
  background: #e76f6f;
  box-shadow: 0 4px 0 0 #d3847e;
}
.deny {
  color: #fff;
  background: tomato;
  margin-left: 2vw;
  box-shadow: 0 4px 0 0 #cb4949;
  border: none;
  border-radius: 0.45em;
}
.deny:hover {
  background: rgb(255, 147, 128);
  box-shadow: 0 4px 0 0 #ef8282;
}
.comment {
  color: #fff;
  background: #0033cc;
  margin-left: 2vw;
  box-shadow: 0 4px 0 0 #0040ff;
  border: none;
  border-radius: 0.45em;
}
.comment:hover {
  background: #99b3ff;
  box-shadow: 0 4px 0 0 #3366ff;
}
.readbtn {
  font-weight: bold;
  font-size: 1vw;
  border: none;
  color: rgb(19, 14, 14);
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.readbtn:hover {
  background: #d3d3d3;
}
.readbtn-select {
  font-weight: bold;
  font-size: 1vw;
  border: none;
  color: rgb(19, 14, 14);
  padding-top: 1vh;
  padding-bottom: 1vh;
  background: #3cb371;
}
