#sidebar {
  --panel-color: var(--panel-color);
  --selected-color: var(--main-color);
}

#sidebar .ant-layout-sider-trigger:hover {
  color: var(--selected-color);
}

#sidebar {
  background-color: black;
}

#sidebar .ant-menu.ant-menu-dark,
#sidebar .ant-layout-sider-trigger {
  background-color: black;
}

#sidebar .ant-menu-item {
  height: 40px;
  max-width: 230px;
  display: flex;
  align-items: center;
  margin: 10px 5px;
  border-radius: 10px;
}

#sidebar .ant-menu-item.ant-menu-item-selected {
  background-color: var(--selected-color);
}

#sidebar .ant-menu-item.ant-menu-item-selected {
  background-color: var(--selected-color);
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-footer {
  /* font-family: "noto-sans-japanese light"; */
  font-size: 12px;
  text-align: center;
  color: white;
  padding: 5px;
  padding-bottom: 0px;
  margin: 0 0px;
}

#sidebar .ant-menu-title-content {
  /* font-family: pt-sans; */
  font-size: 16px;
  margin-left: 36px;
  position: relative;
  top: -1px;
}

#sidebar .anticon.sidebar-icon.ant-menu-item-icon {
  line-height: 35px;
}

#sidebar.ant-layout-sider-collapsed .ant-menu-item {
  transition: background-color 450ms ease-in-out;
}

#sidebar.ant-layout-sider-collapsed .ant-menu-submenu-item {
  transition: background-color 450ms ease-in-out;
}

#sidebar.ant-layout-sider-collapsed .ant-menu-item:not(.ant-menu-item-selected) {
  background-color: rgb(40, 40, 40);
}

#sidebar.ant-layout-sider-collapsed .ant-menu-submenu-title {
  background-color: rgb(40, 40, 40);
  border-radius: 10px;
  width: 80px;
  margin-left: 5px;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: var(--selected-color) !important;
  height: 40px;
  max-width: 210px;
  display: flex;
  align-items: center;
  margin: 10px 5px;
  border-radius: 10px;
}
.site-layout .site-layout-background {
  background: #fff;
}

.sidebar-icon {
  position: absolute !important;
}
