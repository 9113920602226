.p-scrollpanel p {
  padding: 0.5rem;
  line-height: 1.5;
  margin: 0;
}

.p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-b);
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color 0.2s;
}
.p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: #007ad9;
}
