.proceed-container {
  --textcolor: black;
  --hovercolor: red;
  --btncolor: #ffffff;
  --btnhovercolor: #a1eeff;
  --smybolcolor: #3b3b3b;
  --btnborder: #ff0000;
}

.proceed-container {
  width: 35vw;
  padding: 1vw !important;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 1em;
  /* border-style: solid; */
}
.proceed-container .p-dialog-content {
  padding: 0 !important;
}
.proceed-content {
  display: flex;
  flex-direction: row;
  gap: 0.58vw;
  margin: 2vh 1vw;
  align-items: flex-end;
  justify-content: space-between;
}

.proceed-container .proceed-icon {
  margin: 0px;
  text-align: right;
  font-size: 2.34vw;
  color: var(--smybolcolor);
}

.proceed-container .proceed-text {
  font-size: 0.85vw;
  padding-bottom: 1.8vh;
  text-align: right;
}

.proceed-container .btn-grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1vw;
  float: right;
}

.proceed-container .btn {
  border-radius: 8px;
  height: 3em;
  width: 5vw;
  padding: 0.39vw 0.78vw;
  background-color: var(--btncolor);
  color: white;
  border: 1px var(--btnborder) solid;
  /* border-color: var(--buttonborder); */
  /* border-style: solid; */
}

.proceed-container .btn:hover {
  background-color: var(--hovercolor);
  color: var(--btncolor);
  border-color: var(--btnhovercolor);
}
